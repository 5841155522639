import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

const prefix: any = 'custom'

const solidFolder: IconDefinition = {
  icon: [512, 512, [], 'f07b', 'M64 480H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H288c-10.1 0-19.6-4.7-25.6-12.8L243.2 57.6C231.1 41.5 212.1 32 192 32H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64z'],
  iconName: 'solid-folder' as any,
  prefix
}
const solidFolderOpen: IconDefinition = {
  icon: [576, 512, [], 'f07c', 'M88.7 223.8L0 375.8V96C0 60.7 28.7 32 64 32H181.5c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 28.3 18.7 45.3 18.7H416c35.3 0 64 28.7 64 64v32H144c-22.8 0-43.8 12.1-55.3 31.8zm27.6 16.1C122.1 230 132.6 224 144 224H544c11.5 0 22 6.1 27.7 16.1s5.7 22.2-.1 32.1l-112 192C453.9 474 443.4 480 432 480H32c-11.5 0-22-6.1-27.7-16.1s-5.7-22.2 .1-32.1l112-192z'],
  iconName: 'solid-folder-open' as any,
  prefix
}

export default [
  solidFolder,
  solidFolderOpen
]
